@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /* Palette de couleur depuis Adobe XD : */
    --bleu-hover-: #034E79;
    --bleu-main-: #006098;
    --bleu-clair: #51C1E1;
    --bleau-eau-hover-: #019F97;
    --bleu-eau: #00AEA5;
    --fuschia-hover-: #D92F87;
    --fuschia-tonique-: #D94E96;
    --orange-hover-: #F58F29;
    --orange-main-: #F59A29;
    --jaune-hover-: #FACA00;
    --jaune-main-: #FAD600;
    --noir: #2F2F2F;
    --gris-hover-: #515E6E;
    --gris-texte: #687687;
    --gris: #B4BAC2;
    --gris-clair: #F0F1F3;
    --gris-pastel: #FAFAFA;
    --blanc: #FFFFFF;
    --vert-focus-: #94C903;
    --rouge-focus-: #BF0000;
}

body {
    margin: 0;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 300;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    scroll-behavior: smooth;
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.mantine-1garank {
    font-family: aktiv-grotesk, sans-serif;
    font-weight: 300;
    font-style: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.reverse-image {
    transform: scaleX(-1);
}

